import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';

import { Subscription, Observable, ReplaySubject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { GlobalState } from 'src/app/store/states/global.state';

import {
  SalariesLoadAction,
  SalariesLoadImportAction,
} from 'src/app/store/actions/salaries.actions';
import { SalariesParams } from 'src/app/models/salaries/salaries-params';

import { AccountsLoadAction } from 'src/app/store/actions/accounts.actions';
import { AccountsParams } from 'src/app/models/accounts/accounts-params';

import { ProvidersLoadAction } from 'src/app/store/actions/providers.actions';
import { ProvidersParams } from 'src/app/models/providers/providers-params';

import * as salaries from 'src/app/store/selectors/salaries.selectors';
import * as accounts from 'src/app/store/selectors/accounts.selectors';
import * as providers from 'src/app/store/selectors/providers.selectors';

import * as moment from 'moment';
import * as XLSX from 'xlsx';

// Sweet alert
import swal from 'sweetalert2';

// Services
import {
  SharedService,
  CostService,
  NotificationService,
} from 'src/app/services/service.index';

export interface PeriodicElement {
  cuil: string;
  fullname: string;
  type: string;
  price: string;
  date: string;
  costs: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-salaries',
  templateUrl: './salaries.component.html',
  styleUrls: ['./salaries.component.scss'],
})
export class SalariesComponent implements OnInit {
  @ViewChild('showDad') showDad: ElementRef<HTMLElement>;
  @ViewChild('boxScroll') boxScroll: ElementRef;

  displayedColumns: string[] = [
    'cuil',
    'fullname',
    'type',
    'price',
    'date',
    'costs',
  ];
  dataToDisplay = [...ELEMENT_DATA];
  dataSource = new ExampleDataSource(this.dataToDisplay);
  providers: any = [];
  compare: any = [];
  salaries: any = [];
  preSalaries: any = [];
  bodySalaries: any = [];
  costsCenter: any = [];
  failCount: number = 0;
  successCount: number = 0;

  // Salaries
  loading?: boolean;
  error?: boolean;
  public error$?: Observable<boolean>;

  // Cost
  loadingCost?: boolean;
  errorCost?: boolean;
  public errorCost$?: Observable<boolean>;

  // Accounts
  loadingAccounts?: boolean;
  errorAccounts?: boolean;
  public errorAccounts$?: Observable<boolean>;

  // Provider
  loadingProvider?: boolean;
  errorProvider?: boolean;
  public errorProvider$?: Observable<boolean>;

  private subscription: Subscription = new Subscription();

  constructor(
    public store: Store<GlobalState>,
    private _sharedService: SharedService,
    private _notifyService: NotificationService,
    private _costService: CostService
  ) {
    this._sharedService.changeTitile('Carga de Sueldos');
    this._sharedService.changeStatusRowMenu(true);

    // Moment
    moment.locale('es');
  }

  ngOnInit(): void {
    /**
     * Load Data
     */
    this.store.dispatch(new SalariesLoadImportAction(null));
    this.store.dispatch(new SalariesLoadAction(<SalariesParams>{}));
    this.subscription.add(
      this.store
        .pipe(select(salaries.selectSalariesLoading))
        .subscribe((loading) => {
          if (loading) {
            this.store
              .pipe(select(salaries.selectSalariesImport))
              .subscribe(
                (salaries) => (
                  (this.preSalaries = salaries ? salaries[0] : null),
                  this.preUploadFile()
                )
              );
          }
          this.loading = loading;
        })
    );
    this.store.pipe(select(salaries.selectSalariesError)).subscribe((error) => {
      if (error) {
        this._notifyService.showError('Error al obtener datos', 'Salaries');
      }
      this.error = error;
    });

    /**
     * Get Providers
     */
    this.store.dispatch(new ProvidersLoadAction(<ProvidersParams>{}));
    this.subscription.add(
      this.store
        .pipe(select(providers.selectProvidersLoading))
        .subscribe((loading) => {
          if (loading) {
            this.store
              .pipe(select(providers.selectAllProviders))
              .subscribe((providers) => (this.providers = providers));
          }
          this.loading = loading;
        })
    );
    this.store
      .pipe(select(providers.selectProvidersError))
      .subscribe((error) => {
        if (error) {
          this._notifyService.showError(
            'Error al obtener datos',
            'Proveedores'
          );
        }
        this.error = error;
      });

    /**
     * Get Costs Center
     */
    this.store.dispatch(
      new AccountsLoadAction(<AccountsParams>{ accountType: 'outcome' })
    );
    this.subscription.add(
      this.store
        .pipe(select(accounts.selectAccountsLoading))
        .subscribe((loading) => {
          if (loading) {
            this.store
              .pipe(select(accounts.selectAllAccounts))
              .subscribe((accounts) => (this.costsCenter = accounts));
          }
          this.loadingAccounts = loading;
        })
    );
    this.store.pipe(select(accounts.selectAccountsError)).subscribe((error) => {
      if (error) {
        this._notifyService.showError(
          'Error al obtener datos',
          'Centro de Costos'
        );
      }
      this.error = error;
    });
  }

  /**
   * Upload
   */
  uploadFile() {
    swal
      .fire({
        title: 'Carga de sueldos',
        text: 'La siguiente planilla se cargará en la base de datos',
        showCancelButton: true,
        width: 300,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Volver ',
      })
      .then(async (result) => {
        if (result.value) {
          let timeBatch = 0;
          let countBatch = 0;
          this.compare = [...this.salaries];

          // Save
          await Promise.all(
            this.salaries.map(async (element: any, idx: number) => {
              setTimeout(() => {
                this.loadingCost = true;

                // Search Account
                let index;
                const searchAccount = this.costsCenter.find(
                  (elementFind: any) =>
                    elementFind.accountNumber == element.costs
                );
                if (searchAccount) {
                  index = this.costsCenter.indexOf(searchAccount);

                  // Prepare Body
                  this.bodySalaries = {
                    vendor: {
                      nombre: element.fullname,
                      id: element.cuil,
                      billingAddress: '',
                    },
                    date: element.date,
                    xubioInvoiceNumber: `S-${moment(element.date).format(
                      'MMYY'
                    )}-${element.cuil}`,
                    ISOCurrencyCode: element.type,
                    originalAmount: element.price,
                    costItems: [
                      {
                        costCenter: {
                          nombre: this.costsCenter[index].accountName,
                          id: this.costsCenter[index].xubioId,
                        },
                        amount: element.price,
                        notTaxedAmount: 0,
                        description: `Sueldo de ${
                          element.fullname
                        } mes ${moment(element.date)
                          .format('MMMM')
                          .toUpperCase()}`,
                      },
                    ],
                  };

                  // Send Cost
                  this._costService
                    .postCost(this.bodySalaries, true, false)
                    .subscribe(
                      (res) => {
                        let data: any = res;
                        if (
                          data.costs.costRaw.length >= 1 &&
                          data.costs.costRds.length >= 1
                        ) {
                          delete this.compare[idx];
                          this.dataSource.setData(
                            this.compare.filter((element: any) => element)
                          );
                          ++this.successCount;

                          if (this.compare.length === this.successCount) {
                            this.loadingCost = false;
                            this.salaries = [];
                            this.dataSource.setData([]);
                            this._notifyService.showSuccess(
                              'La planilla se ha guardado con éxito!',
                              'Bien'
                            );
                            let el: HTMLElement =
                              document.getElementsByClassName(
                                'btn'
                              )[0] as HTMLElement;
                            el.click();
                          } else if (
                            this.compare.length ===
                            this.successCount + this.failCount
                          ) {
                            this.loadingCost = false;
                            this._notifyService.showError(
                              'Los siguientes registros no fueron cargados',
                              'Error'
                            );
                          }
                        } else {
                          this.loadingCost = false;
                          this._notifyService.showError(
                            'Error al enviar datos',
                            'Cost'
                          );
                        }
                      },
                      (err) => {
                        this.loadingCost = false;
                        this._notifyService.showError(
                          'Error al enviar datos',
                          'Cost'
                        );
                        console.log(err);
                      }
                    );
                } else {
                  ++this.failCount;
                  this.loadingCost = false;
                  this._notifyService.showError(
                    'Error al enviar datos, centro de costo no encontrado',
                    element.fullname
                  );
                }
              }, timeBatch * 1);

              // Batch
              if (countBatch != 10) {
                ++countBatch;
              } else {
                countBatch = 0;
                timeBatch = timeBatch + 3000;
              }
            })
          );
        } else if (result.dismiss === swal.DismissReason.cancel) {
          // Cancel
        }
      });
  }

  /**
   * Pre Upload
   */
  preUploadFile() {
    // Check data
    let error: boolean = false;
    let strError: string;
    let csvErrors: any = [];

    if (this.preSalaries && this.preSalaries.data) {
      this.preSalaries.data.map(async (element: any, index: number) => {
        // Clear errors
        strError = '';

        // Cuil
        if (!element['CUIL'] || element['CUIL'].length === 0) {
          strError += 'CUIL incompleto, ';
        }

        // Date
        if (!element['FECHA'] || element['FECHA'].length === 0) {
          strError += 'Fecha incompleta, ';
        }

        // Full Name
        if (
          !element['Apellido y Nombre'] ||
          element['Apellido y Nombre'].length === 0
        ) {
          strError += 'Apellido y Nombre incompleto, ';
        } else {
          const searchFullName = this.providers.find(
            (elementFind: any) =>
              elementFind.nombre === element['Apellido y Nombre']
          );

          if (!searchFullName) {
            strError += 'Apellido y Nombre incorrecto, ';
          }
        }

        // Currency
        if (!element['Moneda'] || element['Moneda'].length === 0) {
          strError += 'Moneda incompleto, ';
        }

        // Amount
        if (!element['Costo Total'] || element['Costo Total'].length === 0) {
          strError += 'Costo Total incompleto, ';
        }

        // Cost Center
        if (
          !element['Centro de Costos'] ||
          element['Centro de Costos'].length === 0
        ) {
          strError += 'Centro de costos incompleto, ';
        } else {
          const searchAccount = this.costsCenter.find(
            (elementFind: any) =>
              elementFind.accountNumber === element['Centro de Costos']
          );

          if (!searchAccount) {
            strError += 'Centro de costos incorrecto, ';
          }
        }

        // Push data
        if (strError.length > 0) {
          csvErrors.push({
            PROVEEDOR: element['Apellido y Nombre'],
            ERROR: strError.slice(0, -2),
          });
        }

        // Flag
        if (strError.length > 0) {
          if (!error) {
            error = !error;
          }
        }
      });
    }
    if (error) {
      let cancelUpload: HTMLElement = document.getElementsByClassName(
        'btn-show-dad'
      )[0] as HTMLElement;
      setTimeout(() => {
        cancelUpload.click();
      }, 250);

      let title = new Date()
        .toLocaleString()
        .replace(/\//g, '')
        .replace(/:/g, '')
        .replace(' ', '_');

      const ws = XLSX.utils.json_to_sheet(csvErrors);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'output');
      XLSX.writeFile(wb, `Planilla_Sueldos_${title}.xlsx`);

      this._notifyService.showError('El archivo contenia errores', 'Error');
      // this.reloadCurrentRoute();
    } else {
      this.salaries =
        this.preSalaries && this.preSalaries.newData
          ? this.preSalaries.newData
          : null;
      this.compare.push(this.salaries);
      this.dataSource.setData(this.salaries);
    }
  }

  /**
   * Cancel Upload
   */
  cancelUpload() {
    this.salaries = [];
    this.dataSource.setData([]);
  }

  /**
   * Add Data
   */
  addData() {
    const randomElementIndex = Math.floor(Math.random() * ELEMENT_DATA.length);
    this.dataToDisplay = [
      ...this.dataToDisplay,
      ELEMENT_DATA[randomElementIndex],
    ];
    this.dataSource.setData(this.dataToDisplay);
  }

  /**
   * Remove Data
   */
  removeData() {
    this.dataToDisplay = this.dataToDisplay.slice(0, -1);
    this.dataSource.setData(this.dataToDisplay);
  }
}

class ExampleDataSource extends DataSource<PeriodicElement> {
  private _dataStream = new ReplaySubject<PeriodicElement[]>();

  constructor(initialData: PeriodicElement[]) {
    super();
    this.setData(initialData);
  }

  connect(): Observable<PeriodicElement[]> {
    return this._dataStream;
  }

  disconnect() {}

  setData(data: PeriodicElement[]) {
    this._dataStream.next(data);
  }
}
